.privacyMenu {
    width: 100%;
    height: 580px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 100px;
}

.privacyContainer .logoDivitech {
    position: absolute;
    top: 158px;
    left: 50%;
    max-width: 480px;
    transform: translateX(-50%);
    cursor: pointer;
}

.privacyContainer .logoDivitechFooter {
    position: relative;
    top: 50px;
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    
}

.privacyText {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.privacyContainer {
    position: relative;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;
}

.privacyContainer p {
    margin-bottom: 40px !important;
}

.privacyContainer strong {
    font-size: large;
}

.privacyContainer ol {
    margin-left: 20px !important;
    padding-left: 20px !important;
    list-style-type: decimal;
}

.privacyContainer ul {
    margin-left: 20px !important;
    padding-left: 20px !important;
    list-style-type: disc;
}

.privacyContainer li {
    margin-bottom: 10px;
}

.privacyContainer .footer {
    height: 170px;
    width: 100%;
    background-color: #12394D;
    position: absolute;
    margin-top: 50px;
    left: -1px;
}

