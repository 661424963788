

.sectionExplore {
  box-shadow: inset 0 0 0 0 #B18851;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  padding: 5px;


  &:hover {
    box-shadow: inset 100px 0 0 0 #B18851;
    color: #EAEDEF;
  }
}
