.qualityMenu {
    width: 100%;
    height: 580px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 100px;
}

.qualityContainer .logoDivitech {
    position: absolute;
    top: 158px;
    left: 50%;
    max-width: 480px;
    transform: translateX(-50%);
    cursor: pointer;
}

.qualityContainer .logoDivitechFooter {
    position: relative;
    top: 50px;
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    
}

.qualityText {
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.qualityContainer {
    position: relative;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;
}

.qualityContainer p {
    margin-bottom: 40px !important;
}

.qualityContainer strong {
    font-size: large;
}

.qualityContainer ol {
    margin-left: 20px !important;
    padding-left: 20px !important;
    list-style-type: decimal;
}

.qualityContainer ul {
    margin-left: 20px !important;
    padding-left: 20px !important;
    list-style-type: disc;
}

.qualityContainer li {
    margin-bottom: 10px;
}

.qualityContainer .footer {
    height: 170px;
    width: 100%;
    background-color: #12394D;
    position: absolute;
    margin-top: 50px;
    left: -1px;
}

