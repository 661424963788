.cookie-banner {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    z-index: 9999;
    box-shadow: 0px 1px 9px -3px gray;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.cookie-banner:hover {
    background-color: #f2f2f2;
}

.cookie-banner p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.cookie-banner a {
    color: #12394D;
    font-weight: 500;
}

.cookie-banner button {
    margin-top: 10px;
    padding: 8px 20px;
    background-color: #12394D;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cookie-banner button:hover {
    background-color: #091e35;
}