

/***********
  * Colors *
 ***********/
$primary: #12394D;
$bgLight: #FFFFFF;
$textGold: #B18851;
$secondary: #12394D;
$bgLight2: #EAEDEF;
$black: #000;

/****************
  * BreakPoints *
 ***************/

$mobile: 43rem;
$tablet: 62rem;
$smallDesktop: 82rem;
